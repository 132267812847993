import styled from "styled-components/native";
import EnvironmentText from "./EnvironmentText";
import { remToDp, useScreenDimensions } from "../../../utilities/responsive";

const Container = styled(EnvironmentText)`
  margin-bottom: ${(props) => props.marginBottom}px;
  text-align: center;
  font-size: ${(props) => props.fontSize}px;
  font-weight: 500;
`;

function EnvironmentHeader({ children, ...props }) {
  const { vhToDp } = useScreenDimensions();
  return (
    <Container marginBottom={vhToDp(2.5)} fontSize={remToDp(2.5)} {...props}>
      {children}
    </Container>
  );
}

export default EnvironmentHeader;
