import { useStoreActions, useStoreState } from "easy-peasy";
import Environment from "../../presentational/Environment";
import { setAuthToken } from "../../../utilities/api";

function EnvironmentUkGtesList(props) {
  const { isTest } = useStoreState((state) => state.employee);

  const {
    setUserAction,
    setTenantAction,
    setLogoAction,
    setFaviconAction,
    setSpinnerAction,
  } = useStoreActions((actions) => actions);

  function setGeography(geo) {
    let token = "";
    let logo = "";
    let tenant = "STSGBP";
    let email = "";

    if (isTest) {
      if (geo === "ukGTES") {
        tenant = "sts";
        token =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InVrR1RFU1Rlc3RAY2Zicy11cy5jb20iLCJpZCI6InVrR1RFU1Rlc3RAY2Zicy11cy5jb20iLCJpYXQiOjE3MzQwMjMwNTN9.M8ql_VRlby1M78j5tatQ_UoDQPV6esJZ270LytGCheo";
        logo = "stsGTESTest.png";
        email = "ukGTESTest@cfbs-us.com";
      } else if (geo === "ukQAGTES") {
        token =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InN0c1VLR1RFU1FBQGNmYnMtdXMuY29tIiwiaWQiOiJzdHNVS0dURVNRQUBjZmJzLXVzLmNvbSIsImlhdCI6MTczNDAyMzExOX0.doaBAvDNv8oDqz04PHZEDWBzwhGAuXHBP-trnTJUP3k";
        logo = "stsGTESQA.png";
        email = "stsUKGTESQA@cfbs-us.com";
      } else if (geo === "ukSBGTES") {
        token =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InN0c1VLR1RFU1NhbmRib3hAY2Zicy11cy5jb20iLCJpZCI6InN0c1VLR1RFU1NhbmRib3hAY2Zicy11cy5jb20iLCJpYXQiOjE3MzQwMjI4ODl9.ZbQtBjitVbxpJ64oqX7gCquhzsZfMIPZuih-z5GKv7o";
        logo = "stsGTESSandbox.png";
        email = "stsUKGTESSandbox@cfbs-us.com";
      } else if (geo === "ukALGTES") {
        token =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InN0c1VLR1RFU0FscGhhQGNmYnMtdXMuY29tIiwiaWQiOiJzdHNVS0dURVNBbHBoYUBjZmJzLXVzLmNvbSIsImlhdCI6MTczNDAyMzA4OH0.RAANV_0EFw1p4hv6uc2wJbwC6i7w1_wVIqZPfL0a3D4";
        logo = "stsGTESAlpha.png";
        email = "stsUKGTESAlpha@cfbs-us.com";
      }
    } else {
      tenant = "sts";
      token =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InN0c1VLR1RFU1Byb2R1Y3Rpb25AY2Zicy11cy5jb20iLCJpZCI6InN0c1VLR1RFU1Byb2R1Y3Rpb25AY2Zicy11cy5jb20iLCJpYXQiOjE3MzQwMjMwMDV9.Fyhkbssje9iUR9QbeXzj391uNxW44CQSyJg64M3yNbY";
      logo = "stsGTESProduction.png";
      email = "stsUKGTESProduction@cfbs-us.com";
    }

    setUserAction({ email });
    setTenantAction(tenant);
    setLogoAction(logo);
    setAuthToken(token);
    setFaviconAction("favicon_sts.ico");
    setSpinnerAction("rotate_sts.gif");
  }

  return (
    <Environment.List
      list={(isTest
        ? [
            {
              environment: "TEST",
              geographyLabel: "UK - STS GTES",
              geographyValue: "ukGTES",
            },
            {
              environment: "QA",
              geographyLabel: "UK - STS GTES",
              geographyValue: "ukQAGTES",
            },
            {
              environment: "SANDBOX",
              geographyLabel: "UK - STS GTES",
              geographyValue: "ukSBGTES",
            },
            {
              environment: "ALPHA",
              geographyLabel: "UK - STS GTES",
              geographyValue: "ukALGTES",
            },
          ]
        : [
            {
              environment: "",
              geographyLabel: "UK - STS GTES",
              geographyValue: "",
            },
          ]
      ).map(({ geographyLabel, geographyValue, ...environment }) => ({
        geography: geographyLabel,
        onPress: () => setGeography(geographyValue),
        ...environment,
      }))}
      {...props}
    />
  );
}

export default EnvironmentUkGtesList;
