import styled from "styled-components/native";
import { remToDp } from "../../../utilities/responsive";
import Text from "../Text";

const EnvironmentText = styled(Text)`
  font-family: Helvetica Neue;
  font-size: ${remToDp(2)}px;
  font-weight: 500;
`;

export default EnvironmentText;
