import React from "react";
import Environment from "../../presentational/Environment";
import List from "../List";
import Gap from "../../presentational/Gap";
import styled from "styled-components/native";
import { remToDp, useScreenDimensions } from "../../../utilities/responsive";
import { useStoreState } from "easy-peasy";
import Center from "../../presentational/Center";

const SGap = styled(Gap)`
  padding: ${(props) => props.padding}px;
`;

function EnvironmentPage(props) {
  const { vhToDp, vwToDp } = useScreenDimensions();
  const { isTest } = useStoreState((state) => state.employee);
  const padding = vhToDp(1.25);
  return (
    <Center>
      <Environment
        style={{
          maxWidth: vwToDp(60),
          width: "100%",
          paddingHorizontal: remToDp(2),
          paddingVertical: remToDp(4),
        }}
        {...props}
      >
        <Environment.Header>
          Please select a Geography and Environment
        </Environment.Header>

        <List.EnvironmentUs />

        {Boolean(isTest) === true && <SGap padding={padding} />}

        <List.EnvironmentUk />

        {Boolean(isTest) === true && <SGap padding={padding} />}

        <List.EnvironmentIreland />

        {Boolean(isTest) === true && <SGap padding={padding} />}

        <List.EnvironmentDubai />

        {Boolean(isTest) === true && <SGap padding={padding} />}

        <List.EnvironmentUkGtes />
      </Environment>
    </Center>
  );
}

export default EnvironmentPage;
