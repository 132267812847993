import { View } from "react-native";
import Col from "../../presentational/Col";
import Input from "../../presentational/Input";
import Row from "../../presentational/Row";
import InputContainer from "../Input";
import Button from "../Button";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useScreenDimensions } from "../../../utilities/responsive";
import { useEffect } from "react";
import ImagePickerUpload from "../../presentational/Upload/upload";
import Gallery from "../../presentational/Gallery";
import Modal from "../../functional/Modal";

function AddNonroutineTaskForm(props) {
  const { vhToDp } = useScreenDimensions();

  const {
    nonRoutine: {
      nonroutineInput: {
        Title,
        Defect,
        None,
        EWIS,
        RII,
        CriticalTask,
        CDCCL,
        AirworthinessLimitationsItems,
        hours,
        ...nonroutineInput
      },
    },
    employee: { scannedEmployee },
    task: { currentTask },
  } = useStoreState((state) => state);

  const { setNonroutineInputAction, setNonRoutineMXTypesAction } =
    useStoreActions((actions) => actions);

  useEffect(() => {
    currentTask.nonRoutineMatchMXTypes && setNonRoutineMXTypesAction();
  }, [currentTask.nonRoutineMatchMXTypes]);

  const selectImage = (images) => {
    setNonroutineInputAction({
      imageUrls: [...images],
    });
  };

  const deleteImage = (imageToDelete) => {
    const newList = nonroutineInput.imageUrls.filter(
      (image) => image.fileName !== imageToDelete.fileName
    );
    setNonroutineInputAction({
      imageUrls: [...newList],
    });
  };

  return (
    <View {...props}>
      <Input
        label="Title"
        value={Title}
        placeholder="Title"
        onChangeText={(Title) => setNonroutineInputAction({ Title })}
        inputContainerStyle={{ backgroundColor: "#ffffff" }}
      />

      <Input.TextArea
        label="Detail"
        value={Defect}
        placeholder="Defect"
        onChangeText={(Defect) => setNonroutineInputAction({ Defect })}
        inputContainerStyle={{
          backgroundColor: "#ffffff",
        }}
        inputStyle={{
          height: vhToDp(8),
        }}
      />

      <Row>
        {[
          <InputContainer.Chapter />,
          <InputContainer.Subchapter />,
          <InputContainer.Zone />,
        ]
          .concat(scannedEmployee.stsZone ? [<InputContainer.StsZone />] : [])
          .map((children, index) => (
            <Col
              key={index}
              xs={12}
              md={6}
              lg={scannedEmployee.stsZone ? 3 : 4}
            >
              {children}
            </Col>
          ))}

        {[
          {
            title: scannedEmployee.customLabels.nonRoutine.None,
            checked: None,
            onPress: () => {
              const input = {
                None: !None,
                EWIS: false,
                RII: false,
                CriticalTask: false,
                CDCCL: false,
                AirworthinessLimitationsItems: false,
              };

              (scannedEmployee.specialNonRoutineTypes || []).forEach(
                ({ abr }) => {
                  input[abr] = false;
                }
              );

              setNonroutineInputAction(input);
            },
          },
          {
            title: scannedEmployee.customLabels.nonRoutine.EWIS,
            checked: EWIS,
            onPress: () => setNonroutineInputAction({ EWIS: !EWIS }),
            disabled: None,
          },
          {
            title: scannedEmployee.customLabels.nonRoutine.RII,
            checked: RII,
            onPress: () => setNonroutineInputAction({ RII: !RII }),
            disabled: None,
          },
          {
            title: scannedEmployee.customLabels.nonRoutine.CriticalTask,
            checked: CriticalTask,
            onPress: () =>
              setNonroutineInputAction({ CriticalTask: !CriticalTask }),
            disabled: None,
          },
          {
            title: scannedEmployee.customLabels.nonRoutine.CDCCL,
            checked: CDCCL,
            onPress: () => setNonroutineInputAction({ CDCCL: !CDCCL }),
            disabled: None,
          },
          {
            title: scannedEmployee.customLabels.nonRoutine.AWLI,
            checked: AirworthinessLimitationsItems,
            onPress: () =>
              setNonroutineInputAction({
                AirworthinessLimitationsItems: !AirworthinessLimitationsItems,
              }),
            disabled: None,
          },
        ].map((props, index) => (
          <Col key={index} xs={12} md={6} lg={4}>
            <Input.Checkbox {...props} />
          </Col>
        ))}

        {(scannedEmployee.specialNonRoutineTypes || []).map((type, index) => (
          <Col key={index} xs={12} md={6} lg={4}>
            <Input.Checkbox
              title={type.name}
              checked={nonroutineInput[type.abr]}
              disabled={None}
              onPress={() =>
                setNonroutineInputAction({
                  [type.abr]: !nonroutineInput[type.abr],
                })
              }
            />
          </Col>
        ))}

        <Col xs={12} md={6} lg={4}>
          {scannedEmployee.allowImageForNonRoutine && (
            <ImagePickerUpload
              currentImages={nonroutineInput.imageUrls}
              onSelect={selectImage}
            />
          )}
        </Col>

        {(currentTask.nonRoutineLaborItems
          ? [
              <Input
                label="Hours"
                value={hours}
                placeholder="Hours"
                onChangeText={(hours) => setNonroutineInputAction({ hours })}
                inputContainerStyle={{ backgroundColor: "#ffffff" }}
              />,
              <Input.LaborItem />,
            ]
          : []
        ).map((children, index) => (
          <Col key={index} xs={12} md={6} lg={4}>
            {children}
          </Col>
        ))}

        {(nonroutineInput.imageUrls || []).map((image, index) => (
          <Col key={index} xs={6} md={3} lg={2}>
            <Gallery image={image} onDelete={deleteImage}></Gallery>
          </Col>
        ))}
      </Row>

      <Button.AddNonroutineTask />

      {scannedEmployee.doubleInspectionConfirmationWindow && (
        <Modal.ConfirmDuplicateInspection />
      )}
    </View>
  );
}

export default AddNonroutineTaskForm;
